import Cookies from 'js-cookie'

export function setUserId (user_id) {
  Cookies.set('cook_user_id', user_id)
}
export function getUserId () {
  return Cookies.get('cook_user_id')
}

export function setUserType (type) {
  Cookies.set('cook_user_type', type)
}
export function getUserType () {
  return Cookies.get('cook_user_type')
}

export function setUserName (user_name) {
  Cookies.set('cook_user_name', user_name)
}
export function getUserName () {
  return Cookies.get('cook_user_name')
}

export function removeUserId () {
  Cookies.remove('cook_user_id')
}
