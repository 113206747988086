import Vue from 'vue'
import Router from 'vue-router'
import { getUserId } from '../utils/store.js'
import Login from '../components/Login/index.vue'

Vue.use(Router)

const router = new Router({
    routes: [{
        path: '/',
        redirect: '/home',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
        ]
    },
    ]
})
router.beforeEach((to, from, next) => {
    // if (to.path === '/login') {
    //     next()
    // } else {
    //     if (to.meta.requiresAuth && !getUserId()) {
    //         next({
    //             path: '/login'
    //         })
    //     } else {
    //         next()
    //     }
    // }
    next()
})
export default router